<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>【U30进行时】创青春-中关村U30 2019年度优胜者榜单在中关村论坛发布</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>【U30进行时】创青春-中关村U30 2019年度优胜者榜单在中关村论坛发布</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/10/16</span>
          <span class="time-title">中关村U30</span>
          <span class="gold">优胜者
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">中关村论坛由科学技术部、中国科学院、中国科学技术协会、北京市人民政府共同创办于2007年，到今年已经是第十届。论坛以“创新与发展”为永久主题，定位于高科技领域具有影响力的高层次国际性论坛，旨在提供一个共商科技、经济、创新与发展的高端对话平台。</p>
        <p class="fourth-p3">10月16日，2019中关村论坛首场论坛——硬科技创新与青年创业平行论坛在北京成功举办。本次论坛以“硬科技创新与青年创业”为主题，邀请了来自中国、英国、法国、以色列、韩国等10多个国家和地区的青年创业者、企业家、投资人、科学家等创新群体呈现全球最新兴的技术突破与创业方向，共同探讨世界科技创新的发展趋势和最前沿的创新思维，畅谈硬科技改变世界的新机遇以及商业新变量。</p>
        <div class="firth">
          <div><img src="@/assets/img/u303.jpg" alt=""></div>
        </div>
        <p class="fourth-center">创青春-中关村U30 2019年度优胜者榜单在中关村论坛发布</p>
        <p class="fourth-p2">作为开拓培养青年创新精神和创业能力的重要平台，创青春-中关村U30受邀在今年的论坛上发布2019年度优胜者榜单，30位高精尖领域创业青年上榜，斩获2019创青春-中关村U30年度优胜者荣誉。</p>
        <div class="firth">
          <div><img src="@/assets/img/u301.jpg" alt=""></div>
        </div>
        <p class="fourth-center">中关村大河资本创始合伙人、中关村U30发起人刘志硕致发布词</p>
        <p class="fourth-p2">根据创青春-中关村U30秘书处统计，今年的30位年度优胜者平均年龄30岁，年纪最小的只有24岁。他们的另一个特点是：高学历、高融资、高精尖。30位年度优胜者中，博士后学历3人，博士学历及博士在读9人，硕士学历14人，12位拥有海外求学背景，多位优胜者曾在国际知名期刊发表多篇论文或申请到国内外发明专利授权。</p>
        <div class="firth">
          <div><img src="@/assets/img/u302.jpg" alt="" class="img"></div>
        </div>
        <p class="fourth-center">雷宗（27岁）爱玩特智能 创始人</p>
        <p class="fourth-p2">北京大学经济学学士，美国华盛顿圣路易斯大学金融数学硕士。23岁成为门萨会员, 并获得金融风险管理师（FRM）证书。其后进入北美顶尖基金Putnam Investments量化基金管理部，从事中美股票和大宗期货的多空交易、对冲套利。作为第一代AI智能投资的探索者，雷宗致力于将最先进的技术理论带回中国。2016年，雷宗联手北美常春藤PhD，于波士顿成立金融科技公司AInvest，2018年10月成立北京公司，公司专注于将人工智能、大数据技术有效利用于金融领域中，帮助基金、银行等金融机构自动化业务流程、智能化金融决策。雷宗相信人工智能将在未来十年引领金融领域的根本性大变革。</p>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="https://mp.weixin.qq.com/s/oYuwHSccxUGvBazzX71EQw">https://mp.weixin.qq.com/s/oYuwHSccxUGvBazzX71EQw</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newsix"><img src="@/assets/img/jinling1.jpg" alt=""></a>
            <div class="middle">
              <a href="newsix" class="h4">2019(第三届)全球青年创新大会金领奖年度盛典</a>
              <span class="middle-p1">全球青年创新大会</span>
              <span class="middle-p2">金领奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/10/24 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/29 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newone"><img src="@/assets/img/865.jpg" alt=""></a>
            <div class="middle">
              <a href="newone" class="h4">海内外优质创新项目齐聚汕头首届创业之星大赛大奖出炉</a>
              <span class="middle-p1">汕头.创业之星比赛</span>
              <span class="middle-p2">三等奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/15 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
    margin-top: 16px;
    font-family: PingFang-SC-Bold;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #666666;
    text-align: center;
}
p{
    text-align: left;
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin: auto;
    padding: 30px;
    width: 1420px;
    overflow: hidden;
}
.left{
    width: 60%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 767px;
                height: 511px;
            }
            .img{
              width: 767px;
              height: 100%;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
          display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
